// /src/utils/useAxios.js
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react'; // Добавляем useEffect
import { setAuthTokens, setUser, fetchUserDetails } from '../slices/authSlice';

export const baseURL = 'http://217.25.89.177:8001/api';

const useAxios = () => {
  const { authTokens, user, userDetails } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens?.access}` },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    try {
      const decodedUser = jwt_decode(authTokens.access);
      const isExpired = dayjs.unix(decodedUser.exp).diff(dayjs()) < 1;
  
      if (!isExpired) return req;
  
      const response = await axios.post(`${baseURL}/token/refresh/`, {
        refresh: authTokens.refresh,
      });
  
      localStorage.setItem('authTokens', JSON.stringify(response.data));
      dispatch(setAuthTokens(response.data));
      dispatch(setUser(jwt_decode(response.data.access)));
  
      req.headers.Authorization = `Bearer ${response.data.access}`;
      return req;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(setAuthTokens(null));
        localStorage.removeItem('authTokens');
        // Optionally redirect to login page
      }
      return Promise.reject(error);
    }
  });

  useEffect(() => {
    if (user && !userDetails) {
      dispatch(fetchUserDetails(user.user_id));
    }
  }, [user, userDetails, dispatch]);

  return axiosInstance;
};

export default useAxios;
